/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const refreshDashboards = /* GraphQL */ `
  query RefreshDashboards {
    refreshDashboards
  }
`;
export const fetchGameFitPermission = /* GraphQL */ `
  query FetchGameFitPermission($email: String!) {
    fetchGameFitPermission(email: $email)
  }
`;
export const fetchS3UploadUrl = /* GraphQL */ `
  query FetchS3UploadUrl($fileName: String!, $fileType: String) {
    fetchS3UploadUrl(fileName: $fileName, fileType: $fileType)
  }
`;
export const fetchParseJobStatus = /* GraphQL */ `
  query FetchParseJobStatus(
    $fileName: String!
    $bucketName: String
    $user: String
    $season: String
    $sold_to_id: String
  ) {
    fetchParseJobStatus(
      fileName: $fileName
      bucketName: $bucketName
      user: $user
      season: $season
      sold_to_id: $sold_to_id
    )
  }
`;
export const fetchJobStatus = /* GraphQL */ `
  query FetchJobStatus($jobId: String) {
    fetchJobStatus(jobId: $jobId)
  }
`;
export const fetchJoblog = /* GraphQL */ `
  query FetchJoblog($jobId: String) {
    fetchJoblog(jobId: $jobId)
  }
`;
export const getRetailer = /* GraphQL */ `
  query GetRetailer($shipto_id: Int!) {
    getRetailer(shipto_id: $shipto_id) {
      shipto_id
      shipto
      soldto_id
      soldto
      sorg
      country
      buying_group_code
      buying_group
      salesrep_id
      salesrep_current
      shipto_region
      parent_id
      parent_name
      division
      bauerhockey_class
      account_type
      market
      region
      segmentation
      sell_thru_name
      sell_thru_identifier
      retailer_type
      __typename
    }
  }
`;
export const listRetailers = /* GraphQL */ `
  query ListRetailers {
    listRetailers {
      shipto_id
      shipto
      soldto_id
      soldto
      sorg
      country
      buying_group_code
      buying_group
      salesrep_id
      salesrep_current
      shipto_region
      parent_id
      parent_name
      division
      bauerhockey_class
      account_type
      market
      region
      segmentation
      sell_thru_name
      sell_thru_identifier
      retailer_type
      __typename
    }
  }
`;
export const getUpc = /* GraphQL */ `
  query GetUpc($upc_number: Int!) {
    getUpc(upc_number: $upc_number) {
      upc_number
      material_number
      size_dimension
      dim1
      dim2
      material_description
      main_description
      group_desc
      segment
      pgs
      family
      gender
      begin_date
      end_date
      begin_season
      end_season
      launch_date
      smu_group
      prod_type
      glp_status
      ph_number
      flex
      pattern
      hand
      model
      __typename
    }
  }
`;
export const listUpcs = /* GraphQL */ `
  query ListUpcs {
    listUpcs {
      upc_number
      material_number
      size_dimension
      dim1
      dim2
      material_description
      main_description
      group_desc
      segment
      pgs
      family
      gender
      begin_date
      end_date
      begin_season
      end_season
      launch_date
      smu_group
      prod_type
      glp_status
      ph_number
      flex
      pattern
      hand
      model
      __typename
    }
  }
`;
export const getFirstDimensions = /* GraphQL */ `
  query GetFirstDimensions {
    getFirstDimensions {
      dim1
      order_num
      __typename
    }
  }
`;
export const getSecondDimensions = /* GraphQL */ `
  query GetSecondDimensions {
    getSecondDimensions {
      dim2
      order_num
      __typename
    }
  }
`;
export const getLevelOneCategories = /* GraphQL */ `
  query GetLevelOneCategories {
    getLevelOneCategories {
      id
      value
      __typename
    }
  }
`;
export const getLevelTwoCategories = /* GraphQL */ `
  query GetLevelTwoCategories {
    getLevelTwoCategories {
      id
      value
      __typename
    }
  }
`;
export const getAllCategories = /* GraphQL */ `
  query GetAllCategories {
    getAllCategories {
      id
      main_desc
      group_desc
      pgs
      begin_season
      bth_or_holiday
      main_desc2
      category
      top_planning_unit
      __typename
    }
  }
`;
export const getAllProducts = /* GraphQL */ `
  query GetAllProducts {
    getAllProducts {
      category_id
      id
      name
      display_name
      value
      main_description
      group_description
      category_value
      parent_category
      level1_bth_or_holiday_id
      level2_main_description_id
      top_planning_inputs_id
      __typename
    }
  }
`;
export const getCatalogId = /* GraphQL */ `
  query GetCatalogId($sales_org: String!, $bauerhockey_class: String!) {
    getCatalogId(sales_org: $sales_org, bauerhockey_class: $bauerhockey_class) {
      catalog
      __typename
    }
  }
`;
export const getPgsSort = /* GraphQL */ `
  query GetPgsSort {
    getPgsSort {
      name
      sort
      __typename
    }
  }
`;
export const getGenderSort = /* GraphQL */ `
  query GetGenderSort {
    getGenderSort {
      gender
      sort
      __typename
    }
  }
`;
export const getSuggestedDataByMonth = /* GraphQL */ `
  query GetSuggestedDataByMonth(
    $soldto_id: Int!
    $category: String!
    $start_date: String
    $end_date: String
    $current_year: Int
    $previous_year: Int
    $next_year: Int
  ) {
    getSuggestedDataByMonth(
      soldto_id: $soldto_id
      category: $category
      start_date: $start_date
      end_date: $end_date
      current_year: $current_year
      previous_year: $previous_year
      next_year: $next_year
    ) {
      soldto_id
      shipto_id
      category
      units
      gross
      currency
      month
      year
      season
      type
      segment
      __typename
    }
  }
`;
export const getSuggestedDataByMonthShipTo = /* GraphQL */ `
  query GetSuggestedDataByMonthShipTo(
    $shipto_id: Int!
    $category: String!
    $start_date: String
    $end_date: String
    $current_year: Int
    $previous_year: Int
    $next_year: Int
  ) {
    getSuggestedDataByMonthShipTo(
      shipto_id: $shipto_id
      category: $category
      start_date: $start_date
      end_date: $end_date
      current_year: $current_year
      previous_year: $previous_year
      next_year: $next_year
    ) {
      soldto_id
      shipto_id
      category
      units
      gross
      currency
      month
      year
      season
      type
      segment
      __typename
    }
  }
`;
export const segmentData = /* GraphQL */ `
  query SegmentData(
    $soldto_id: Int!
    $category: String!
    $start_date: String
    $end_date: String
    $current_year: Int
    $previous_year: Int
    $next_year: Int
    $sorg: String
    $region: String
    $market: String
    $filter: Int
  ) {
    segmentData(
      soldto_id: $soldto_id
      category: $category
      start_date: $start_date
      end_date: $end_date
      current_year: $current_year
      previous_year: $previous_year
      next_year: $next_year
      sorg: $sorg
      region: $region
      market: $market
      filter: $filter
    ) {
      soldto_id
      category
      units
      segment
      year
      season
      type
      begin_season
      end_season
      __typename
    }
  }
`;
export const getRetailers = /* GraphQL */ `
  query GetRetailers($rep_id: Int) {
    getRetailers(rep_id: $rep_id) {
      soldto_id
      soldto
      __typename
    }
  }
`;
export const getAllRetailers = /* GraphQL */ `
  query GetAllRetailers($rep_id: Int) {
    getAllRetailers(rep_id: $rep_id) {
      soldto_id
      soldto
      __typename
    }
  }
`;
export const getRetailerData = /* GraphQL */ `
  query GetRetailerData($soldto_id: Int!) {
    getRetailerData(soldto_id: $soldto_id) {
      shipto_id
      shipto
      soldto_id
      soldto
      sorg
      country
      buying_group_code
      buying_group
      salesrep_id
      salesrep_current
      shipto_region
      parent_id
      parent_name
      division
      bauerhockey_class
      account_type
      market
      region
      segmentation
      sell_thru_name
      sell_thru_identifier
      retailer_type
      __typename
    }
  }
`;
export const getMonthlyTotals = /* GraphQL */ `
  query GetMonthlyTotals($soldto_id: Int!, $category: String, $season: String) {
    getMonthlyTotals(
      soldto_id: $soldto_id
      category: $category
      season: $season
    ) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      booking_date
      units
      segment
      type
      split
      __typename
    }
  }
`;
export const getMonthlyTotalsBySoldtoId = /* GraphQL */ `
  query GetMonthlyTotalsBySoldtoId($soldto_id: Int!, $season: String) {
    getMonthlyTotalsBySoldtoId(soldto_id: $soldto_id, season: $season) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      booking_date
      units
      segment
      type
      split
      __typename
    }
  }
`;
export const getSegmentPercentages = /* GraphQL */ `
  query GetSegmentPercentages(
    $soldto_id: Int!
    $category: String
    $season: String
  ) {
    getSegmentPercentages(
      soldto_id: $soldto_id
      category: $category
      season: $season
    ) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      segments
      weight
      __typename
    }
  }
`;
export const getMaterialSplit = /* GraphQL */ `
  query GetMaterialSplit($soldto_id: Int!, $category: String, $season: String) {
    getMaterialSplit(
      soldto_id: $soldto_id
      category: $category
      season: $season
    ) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      material_split
      split_id
      __typename
    }
  }
`;
export const loadUpcData = /* GraphQL */ `
  query LoadUpcData($soldto_id: Int, $category: String, $season: String) {
    loadUpcData(soldto_id: $soldto_id, category: $category, season: $season) {
      upc
      material
      quantity
      booking_date
      __typename
    }
  }
`;
export const fetchMaterials = /* GraphQL */ `
  query FetchMaterials(
    $soldto_id: Int!
    $category: String
    $start_year: Int
    $start_month: Int
    $end_year: Int
    $end_month: Int
    $sorg: String
    $currency_type: String
    $smu_group_sec: String
    $smu_group_sdc: String
    $region: String
    $market: String
    $filter: Int
    $market_filter: String
    $category_id: Int
    $ship_to_id: Int
    $top_planning_input_id: String!
    $main_description: String!
  ) {
    fetchMaterials(
      soldto_id: $soldto_id
      category: $category
      start_year: $start_year
      start_month: $start_month
      end_year: $end_year
      end_month: $end_month
      sorg: $sorg
      currency_type: $currency_type
      smu_group_sec: $smu_group_sec
      smu_group_sdc: $smu_group_sdc
      region: $region
      market: $market
      filter: $filter
      market_filter: $market_filter
      category_id: $category_id
      ship_to_id: $ship_to_id
      top_planning_input_id: $top_planning_input_id
      main_description: $main_description
    ) {
      material
      description
      model
      gender
      family
      segment
      prod_type
      amount
      weight
      type
      shelfdate
      __typename
    }
  }
`;
export const fetchUpc = /* GraphQL */ `
  query FetchUpc(
    $soldto_id: Int!
    $category: String
    $category_value: String
    $planning_year: Int
    $start_month: Int
    $end_month: Int
    $sorg: String
    $currency_type: String
    $smu_group_sec: String
    $smu_group_sdc: String
    $region: String
    $market: String
    $filter: Int
    $market_filter: String
    $start_date: String
    $end_date: String
    $begin_season_filter_str: String
    $top_planning_input_id: String
    $category_id: Int
    $ship_to_id: Int
  ) {
    fetchUpc(
      soldto_id: $soldto_id
      category: $category
      category_value: $category_value
      planning_year: $planning_year
      start_month: $start_month
      end_month: $end_month
      sorg: $sorg
      currency_type: $currency_type
      smu_group_sec: $smu_group_sec
      smu_group_sdc: $smu_group_sdc
      region: $region
      market: $market
      filter: $filter
      market_filter: $market_filter
      start_date: $start_date
      end_date: $end_date
      begin_season_filter_str: $begin_season_filter_str
      top_planning_input_id: $top_planning_input_id
      category_id: $category_id
      ship_to_id: $ship_to_id
    ) {
      upc
      material
      description
      dim1
      dim2
      model
      gender
      family
      segment
      prod_type
      amount
      weight
      type
      units
      shelfdate
      __typename
    }
  }
`;
export const fetchLastYearBookings = /* GraphQL */ `
  query FetchLastYearBookings(
    $soldto_id: Int
    $category: String
    $req_delivery_dte_start: String
    $req_delivery_dte_end: String
    $previousYear: Int
    $season: String
  ) {
    fetchLastYearBookings(
      soldto_id: $soldto_id
      category: $category
      req_delivery_dte_start: $req_delivery_dte_start
      req_delivery_dte_end: $req_delivery_dte_end
      previousYear: $previousYear
      season: $season
    ) {
      product_type
      sum_whole_price_o
      sum_whole_price_i
      sum_whole_price_net
      units
      segment
      __typename
    }
  }
`;
export const fetchInventory = /* GraphQL */ `
  query FetchInventory($soldto_id: Int!, $category: String) {
    fetchInventory(soldto_id: $soldto_id, category: $category) {
      date
      upc
      inventory
      __typename
    }
  }
`;
export const fetchInventoryAll = /* GraphQL */ `
  query FetchInventoryAll(
    $soldto_id: Int!
    $category: String
    $start_date: String
    $end_date: String
    $begin_season_filter_str: String
    $top_planning_input_id: String
  ) {
    fetchInventoryAll(
      soldto_id: $soldto_id
      category: $category
      start_date: $start_date
      end_date: $end_date
      begin_season_filter_str: $begin_season_filter_str
      top_planning_input_id: $top_planning_input_id
    ) {
      upc
      material
      units
      segment
      year
      __typename
    }
  }
`;
export const getSellThruUnits = /* GraphQL */ `
  query GetSellThruUnits(
    $soldto_id: Int!
    $category: String
    $start_date: String
    $end_date: String
    $begin_season_filter_str: String
    $top_planning_input_id: String
  ) {
    getSellThruUnits(
      soldto_id: $soldto_id
      category: $category
      start_date: $start_date
      end_date: $end_date
      begin_season_filter_str: $begin_season_filter_str
      top_planning_input_id: $top_planning_input_id
    ) {
      units
      segment
      month
      year
      __typename
    }
  }
`;
export const getRepeatUnits = /* GraphQL */ `
  query GetRepeatUnits(
    $soldto_id: Int!
    $category: String!
    $start_date: String
    $end_date: String
    $current_year: Int
    $previous_year: Int
    $next_year: Int
  ) {
    getRepeatUnits(
      soldto_id: $soldto_id
      category: $category
      start_date: $start_date
      end_date: $end_date
      current_year: $current_year
      previous_year: $previous_year
      next_year: $next_year
    ) {
      soldto_id
      shipto_id
      category
      units
      gross
      currency
      month
      year
      season
      type
      segment
      __typename
    }
  }
`;
export const openOrdersData = /* GraphQL */ `
  query OpenOrdersData(
    $soldto_id: Int!
    $category: String
    $season_year: Int!
  ) {
    openOrdersData(
      soldto_id: $soldto_id
      category: $category
      season_year: $season_year
    ) {
      units
      type
      segment
      material
      upc
      year
      request_year
      request_month
      req_delivery_dte
      __typename
    }
  }
`;
export const getPreviousSellThruUnits = /* GraphQL */ `
  query GetPreviousSellThruUnits(
    $soldto_id: Int!
    $category: String
    $year: Int
    $ref_material: String
    $ref_prev_material: String
    $start_date: String
    $end_date: String
    $begin_season_filter_str: String
    $top_planning_input_id: String
  ) {
    getPreviousSellThruUnits(
      soldto_id: $soldto_id
      category: $category
      year: $year
      ref_material: $ref_material
      ref_prev_material: $ref_prev_material
      start_date: $start_date
      end_date: $end_date
      begin_season_filter_str: $begin_season_filter_str
      top_planning_input_id: $top_planning_input_id
    ) {
      upc_number
      material
      units_sold
      dim1
      dim2
      family
      segment
      gender
      month
      year
      begin_year
      __typename
    }
  }
`;
export const getAllReferenceUpcs = /* GraphQL */ `
  query GetAllReferenceUpcs($category: String!, $sorg: String!) {
    getAllReferenceUpcs(category: $category, sorg: $sorg) {
      new_upc_number
      new_material_number
      ref_upc
      ref_material
      ref_description
      ref_prev_upc
      ref_prev_material
      ref_prev_description
      begin_algorithm_date
      end_algorithm_date
      ref_prev_begin_algorithm_date2
      ref_prev_end_algorithm_date
      __typename
    }
  }
`;
export const getShipToData = /* GraphQL */ `
  query GetShipToData($sold_to_id: Int!) {
    getShipToData(sold_to_id: $sold_to_id) {
      ship_to_id
      ship_to_name
      __typename
    }
  }
`;
export const getHistoricGrowth = /* GraphQL */ `
  query GetHistoricGrowth($soldto_id: Int!, $category: String) {
    getHistoricGrowth(soldto_id: $soldto_id, category: $category) {
      soldto_id
      gross
      __typename
    }
  }
`;
export const getDeadlines = /* GraphQL */ `
  query GetDeadlines($category: String, $season: String) {
    getDeadlines(category: $category, season: $season) {
      id
      category_id
      Material_Num
      Mat_Desc
      Segment
      OnShelf
      OnShelf_ROW
      ROW_DC_DL
      NA_DC_DL
      HS_DL
      __typename
    }
  }
`;
export const getActuals = /* GraphQL */ `
  query GetActuals(
    $soldto_id: Int
    $selling_year: Int
    $category: String
    $sorg: String
    $currency_type: String
  ) {
    getActuals(
      soldto_id: $soldto_id
      selling_year: $selling_year
      category: $category
      sorg: $sorg
      currency_type: $currency_type
    ) {
      soldto_id
      shipto_id
      amount
      upc_number
      material_number
      category
      units_sold
      begin_year
      segment
      family
      gender
      selling_year
      selling_month
      launch_type
      __typename
    }
  }
`;
export const getPreviousSellInData = /* GraphQL */ `
  query GetPreviousSellInData(
    $soldto_id: Int
    $category: String
    $req_delivery_dte_start: String
    $req_delivery_dte_end: String
    $previousYear: Int
    $season: String
  ) {
    getPreviousSellInData(
      soldto_id: $soldto_id
      category: $category
      req_delivery_dte_start: $req_delivery_dte_start
      req_delivery_dte_end: $req_delivery_dte_end
      previousYear: $previousYear
      season: $season
    ) {
      type
      units
      month
      segment
      __typename
    }
  }
`;
export const fetchLastSavedData = /* GraphQL */ `
  query FetchLastSavedData(
    $soldto_id: Int
    $sorg: String
    $season: String
    $modified_by: String
  ) {
    fetchLastSavedData(
      soldto_id: $soldto_id
      sorg: $sorg
      season: $season
      modified_by: $modified_by
    ) {
      category
      booking_month
      quantity
      upc
      material_number
      segment
      launch_migrating
      begin_date
      total_amount
      __typename
    }
  }
`;
export const fetchOrdersData = /* GraphQL */ `
  query FetchOrdersData(
    $soldto_id: Int
    $sorg: String
    $season: String
    $modified_by: String
    $seasonYear: String
  ) {
    fetchOrdersData(
      soldto_id: $soldto_id
      sorg: $sorg
      season: $season
      modified_by: $modified_by
      seasonYear: $seasonYear
    )
  }
`;
export const fetchSubmittedToBbData = /* GraphQL */ `
  query FetchSubmittedToBbData(
    $soldto_id: Int!
    $sorg: String!
    $season: String!
    $modified_by: String!
    $currency_type: String!
  ) {
    fetchSubmittedToBbData(
      soldto_id: $soldto_id
      sorg: $sorg
      season: $season
      modified_by: $modified_by
      currency_type: $currency_type
    ) {
      bucket
      month
      order_qty
      __typename
    }
  }
`;
export const getCatalog = /* GraphQL */ `
  query GetCatalog($sales_org: String!, $retailer_type: String!) {
    getCatalog(sales_org: $sales_org, retailer_type: $retailer_type) {
      rule_id
      catalog
      rule_description
      __typename
    }
  }
`;
export const getUploadToBbUrlAndAuthKey = /* GraphQL */ `
  query GetUploadToBbUrlAndAuthKey(
    $application: String!
    $module: String!
    $sub_module: String!
  ) {
    getUploadToBbUrlAndAuthKey(
      application: $application
      module: $module
      sub_module: $sub_module
    ) {
      key_
      value_
      __typename
    }
  }
`;
export const getQuickSightDashboards = /* GraphQL */ `
  query GetQuickSightDashboards(
    $jwtToken: String!
    $payloadSub: String!
    $email: String!
    $username: String!
  ) {
    getQuickSightDashboards(
      jwtToken: $jwtToken
      payloadSub: $payloadSub
      email: $email
      username: $username
    ) {
      dashboardId
      name
      __typename
    }
  }
`;
export const fetchDashboardEmbedUrl = /* GraphQL */ `
  query FetchDashboardEmbedUrl(
    $dashboardId: String!
    $username: String!
    $email: String!
  ) {
    fetchDashboardEmbedUrl(
      dashboardId: $dashboardId
      username: $username
      email: $email
    )
  }
`;
export const getRefYears = /* GraphQL */ `
  query GetRefYears($category: String) {
    getRefYears(category: $category) {
      new_upc_number
      new_material_number
      ref_upc
      ref_material
      ref_prev_upc
      ref_prev_material
      begin_year
      end_year
      ref_begin_year
      ref_end_year
      ref_prev_begin_year
      ref_prev_end_year
      __typename
    }
  }
`;
export const getCustomMaterialAllocations = /* GraphQL */ `
  query GetCustomMaterialAllocations(
    $soldto_id: Int!
    $category: String
    $year: Int
  ) {
    getCustomMaterialAllocations(
      soldto_id: $soldto_id
      category: $category
      year: $year
    ) {
      id
      soldto_id
      material_number
      amount
      month
      month_index
      segment
      type
      units_entered
      category
      year
      active
      modified_by
      __typename
    }
  }
`;
export const getCustomMaterialAllocationsBySoldtoId = /* GraphQL */ `
  query GetCustomMaterialAllocationsBySoldtoId($soldto_id: Int!, $year: Int) {
    getCustomMaterialAllocationsBySoldtoId(soldto_id: $soldto_id, year: $year) {
      id
      soldto_id
      material_number
      amount
      month
      month_index
      segment
      type
      units_entered
      category
      year
      active
      modified_by
      __typename
    }
  }
`;
export const getSegmentsFromProduct = /* GraphQL */ `
  query GetSegmentsFromProduct(
    $group_desc: String!
    $season: String!
    $category: String!
  ) {
    getSegmentsFromProduct(
      group_desc: $group_desc
      season: $season
      category: $category
    ) {
      id
      group_desc
      pgs
      __typename
    }
  }
`;
export const getStockUnitsBySoldToId = /* GraphQL */ `
  query GetStockUnitsBySoldToId(
    $sold_to_id: Int!
    $category_id: String!
    $begin_year: Int!
    $top_planning_input_id: String!
  ) {
    getStockUnitsBySoldToId(
      sold_to_id: $sold_to_id
      category_id: $category_id
      begin_year: $begin_year
      top_planning_input_id: $top_planning_input_id
    ) {
      upc_number
      end_day
      material_number
      sold_to_id
      segment
      retailer_stock_units
      type
      __typename
    }
  }
`;
export const getCategoryIdList = /* GraphQL */ `
  query GetCategoryIdList($category_id: Int) {
    getCategoryIdList(category_id: $category_id) {
      ids
      __typename
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
